<template>
  <span :class="subscription">{{ text }}</span>
</template>

<script>
export default {
  name: 'gradient-text',
  props: {
    subscription: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.pro {
  width: fit-content;
  background: linear-gradient(205deg, rgba(0, 255, 133, 0.7) 19.97%, rgba(47, 211, 133, 0) 94.25%), $bb-neon-purple;
  @apply text-transparent bg-clip-text;
}

.pro_plus {
  width: fit-content;
  background: linear-gradient(205deg, rgba(240, 75, 165, 0.7) 5.67%, rgba(240, 75, 165, 0) 94.25%), $bb-neon-purple;
  @apply text-transparent bg-clip-text;
}

.enterprise {
  width: fit-content;
  background: linear-gradient(204.06deg, rgba(240, 75, 165, 0.85) 5.67%, rgba(240, 75, 165, 0) 94.25%), #f3a64c;
  @apply text-transparent bg-clip-text;
}
</style>
