<template>
  <base-modal>
    <template #header>
      <div class="flex">
        <div class="flex-none w-12"></div>
        <div class="flex-grow">
          <h3 class="h3 mb-2">Subscriptions and pricing</h3>

          <div class="p1 font-normal">
            Choose your plan for <span class="font-bold">{{ store.name }}</span>
            <badge
              v-if="account.enterprise"
              enterprise
            >
              Enterprise
            </badge>
          </div>

          <div
            v-if="account.enterprise"
            class="mt-8"
          >
            <div>
              You have a Bidbrain Enterprise account and can activate any of the following subscriptions for
              {{ store.name }}
            </div>
            <div>Contact customer care for detailed pricing.</div>
          </div>
          <div v-else>You can upgrade, downgrade or cancel your subscription anytime.</div>

          <div
            v-if="updateError || cancelError"
            class="text-danger mt-4"
          >
            Could not update subscription
          </div>
        </div>
        <div class="flex-none w-12">
          <button
            @click="$emit('close')"
            class="text-bb-mid-grey flex items-center text-xs cursor-pointer"
          >
            Close
            <ic-close
              :size="14"
              class="ml-1"
            />
          </button>
        </div>
      </div>
    </template>

    <template #default>
      <div class="space-y-8">
        <base-alert
          v-if="downgradePending"
          type="warn"
        >
          <template #title>
            <span class="md:hidden">Downgrade&nbsp;pending</span>
          </template>
          <template #content>
            Your current
            <subscription-badge
              :slug="store.subscription_slug"
              class="mx-1"
            />
            subscription will be downgraded on <span class="font-bold ml-1">{{ downgradeDate }}</span
            >.
          </template>
          <template #action>
            <the-button
              @click="cancelDowngrade"
              primary
            >
              <template #default> Cancel&nbsp;downgrade </template>
              <template #icon>
                <ic-close :size="18" />
              </template>
            </the-button>
          </template>
        </base-alert>

        <subscription-selector
          :store-id="storeId"
          :disable="downgradePending"
        />

        <pricing-links />
      </div>
    </template>
  </base-modal>
</template>

<script>
import SubscriptionSelector from '@/components/subscriptions/SubscriptionSelector'
import IcClose from 'vue-material-design-icons/Close'
import { mapActions, mapGetters } from 'vuex'
import Badge from '@/components/base/Badge'
import BaseAlert from '@/components/alert/BaseAlert'
import SubscriptionBadge from '@/components/subscriptions/SubscriptionBadge'
import PricingLinks from '@/components/subscriptions/PricingLinks'
import Toast from '@/components/shared/Toast'

export default {
  name: 'subscription-select-modal',
  components: { PricingLinks, SubscriptionBadge, BaseAlert, Badge, SubscriptionSelector, IcClose, Toast },
  props: {
    storeId: {
      type: Number,
    },
  },
  modalProps: {
    height: 'auto',
    maxWidth: 900,
    scrollable: true,
  },
  data() {
    return {
      cancelingDowngrade: false,
      cancelError: null,
    }
  },
  computed: {
    ...mapGetters({
      storeById: 'store/storeById',
      updateStoreErrorById: 'store/getUpdateStoreErrorById',
      account: 'account/getAccount',
    }),
    store() {
      return this.storeById(this.storeId)
    },
    updateError() {
      return this.updateStoreErrorById(this.storeId)
    },
    downgradePending() {
      return this.store.states.downgrade.status
    },
    downgradeDate() {
      return this.store.states.downgrade.data.expire.substr(0, 10)
    },
  },
  methods: {
    ...mapActions({
      loadStore: 'store/loadStoreDetails',
      revertDowngrade: 'store/revertDowngrade',
    }),
    cancelDowngrade() {
      this.cancelingDowngrade = true
      this.cancelError = null

      this.revertDowngrade({
        storeId: this.store.id,
      })
        .then(() => {
          this.loadStore(this.store.id)
          this.$toast.info({
            component: Toast,
            props: {
              title: 'Information',
              message: 'Your subscription downgrade has been cancelled.',
              type: 'info',
            },
          })
        })
        .catch(e => (this.cancelError = e))
        .finally(() => (this.cancelingDowngrade = false))
    },
  },
}
</script>
