<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Check done</title>
    <path
      d="M9.00002 16.2001L4.80002 12.0001L3.40002 13.4001L9.00002 19.0001L21 7.0001L19.6 5.6001L9.00002 16.2001Z"
      :fill="primaryFill"
    />
    <path
      v-if="subscription !== 'free'"
      d="M9.00002 16.2001L4.80002 12.0001L3.40002 13.4001L9.00002 19.0001L21 7.0001L19.6 5.6001L9.00002 16.2001Z"
      :fill="secondaryFill"
      fill-opacity="0.85"
    />
    <defs>
      <linearGradient
        v-if="subscription === 'pro'"
        id="gradient_pro"
        x1="17.7695"
        y1="5.6001"
        x2="12.5368"
        y2="20.9925"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset="0.161458"
          stop-color="#00FF85"
        />
        <stop
          offset="1"
          stop-color="#2FD385"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        v-if="subscription === 'pro_plus'"
        id="gradient_pro_plus"
        x1="17.7695"
        y1="5.6001"
        x2="12.5368"
        y2="20.9925"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F04BA5" />
        <stop
          offset="1"
          stop-color="#F04BA5"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        v-if="subscription === 'enterprise'"
        id="gradient_enterprise"
        x1="17.7695"
        y1="5.6001"
        x2="12.5368"
        y2="20.9925"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F04BA5" />
        <stop
          offset="1"
          stop-color="#F04BA5"
          stop-opacity="0"
        />
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
const PRIMARY_FILL = {
  free: '#3D3A5C',
  pro: '#6E19F0',
  pro_plus: '#6E19F0',
  enterprise: '#F3A64C',
}

const SECONDARY_FILL = {
  free: '',
  pro: 'url(#gradient_pro)',
  pro_plus: 'url(#gradient_pro_plus)',
  enterprise: 'url(#gradient_enterprise)',
}

export default {
  name: 'ic-done',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
    subscription: {
      type: String,
      default: 'free',
      validator: v => ['free', 'pro', 'pro_plus', 'enterprise'].includes(v),
    },
  },
  computed: {
    primaryFill() {
      return PRIMARY_FILL[this.subscription]
    },
    secondaryFill() {
      return SECONDARY_FILL[this.subscription]
    },
  },
}
</script>
