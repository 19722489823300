<template>
  <ol class="grid grid-cols-1 lg:grid-cols-3 gap-4">
    <li
      v-for="subscription in subscriptions"
      v-if="subscription.slug !== 'enterprise'"
      :key="subscription.slug"
    >
      <subscription-card
        :subscription="subscription"
        :button-text="buttonText(subscription.slug)"
        @select="selectSubscription"
        :disabled="updating || (disable && !noSelected)"
        :selected="subscription.slug === store.subscription_slug && !noSelected"
        :show-pricing="showPricing"
      />
    </li>
  </ol>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SubscriptionCard from '@/components/subscriptions/SubscriptionCard'
import CardModal from '@/components/modals/CardModal'
import Toast from '@/components/shared/Toast'

const SUBSCRIPTION_GRADIENT = {
  free: null,
  pro: 'gradient-blue',
  pro_plus: 'gradient-purple',
  enterprise: 'gradient-orange',
}

export default {
  name: 'subscription-selector',
  components: { SubscriptionCard, Toast },
  emits: ['selected'],
  props: {
    storeId: {
      type: Number,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    noSelected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      updating: false,
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'auth/getIsAdmin',
      subscriptions: 'subscription/getSubscriptions',
      storeById: 'store/storeById',
      account: 'account/getAccount',
      cards: 'card/getCards',
      updateStoreErrorById: 'store/getUpdateStoreErrorById',
    }),
    store() {
      return this.storeById(this.storeId)
    },
    storeSubscriptionSlug() {
      return this.storeById(this.storeId).subscription_slug
    },
    updateError() {
      return this.updateStoreErrorById(this.storeId)
    },
    showPricing() {
      return !this.account.enterprise
    },
  },
  watch: {
    updateError(error) {
      if (!!error)
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Cannot update subscription',
            type: 'error',
          },
        })
    },
  },
  methods: {
    ...mapActions({
      loadSubscriptionsIfNeeded: 'subscription/loadSubscriptionsIfNeeded',
      loadStoreById: 'store/loadStoreDetails',
      updateStore: 'store/updateStore',
      loadCardsIfNeeded: 'card/loadCardsIfNeeded',
      removeUpdateError: 'store/removeUpdateError',
    }),
    buttonText(subscriptionSlug) {
      if (this.noSelected) {
        return 'Select'
      }

      if (this.store.subscription_slug === subscriptionSlug) {
        return 'Current plan'
      }

      if (
        this.store.subscription_slug === 'free' ||
        (this.store.subscription_slug === 'pro' && subscriptionSlug === 'pro_plus')
      ) {
        return 'Upgrade now'
      }

      if (
        (this.store.subscription_slug === 'pro_plus' && subscriptionSlug === 'pro') ||
        (this.store.subscription_slug !== 'free' && subscriptionSlug === 'free')
      ) {
        return 'Downgrade'
      }

      return 'Contact us'
    },
    titleClasses(subscription) {
      return SUBSCRIPTION_GRADIENT[subscription]
    },
    selectSubscription(subscription) {
      // Upgrade from Free -> Pro or Pro+ requires a connected credit card.
      // Exceptions are made for enterprise accounts and managers acting as account owner (admins).
      if (this.account.enterprise || this.isAdmin) {
        this.updateStoreSubscription(subscription)
        return
      }

      if (subscription.slug !== 'free' && this.cards.length === 0) {
        this.$modal.show(
          CardModal,
          {
            parent: 'subscription_selector',
            onSubmitted: () => this.updateStoreSubscription(subscription),
          },
          CardModal.modalProps,
        )
      } else {
        this.updateStoreSubscription(subscription)
      }
    },
    updateStoreSubscription(subscription) {
      this.updating = true

      this.updateStore({ storeId: this.storeId, data: { subscription_id: subscription.id } })
        .then(_ => this.$emit('selected', subscription.id))
        .finally(() => (this.updating = false))
    },
  },
  beforeMount() {
    this.loadCardsIfNeeded()
    this.loadSubscriptionsIfNeeded()
  },
  beforeDestroy() {
    this.removeUpdateError(this.storeId)
  },
}
</script>

<style scoped>
.gradient-blue {
  @apply text-transparent bg-clip-text bg-gradient-to-br from-blue-900 to-blue-600;
}

.gradient-purple {
  @apply text-transparent bg-clip-text bg-gradient-to-br from-purple-800 to-purple-600;
}

.gradient-orange {
  @apply text-transparent bg-clip-text bg-gradient-to-br from-orange-500 to-orange-600;
}
</style>
