<template>
  <div
    class="subscription-card shadow-md space-y-4"
    :class="{ selected: selected }"
  >
    <h3>
      <gradient-text
        :subscription="subscription.slug"
        :text="subscription.name"
      />
    </h3>

    <div
      v-if="showPricing"
      style="height: 65px"
    >
      <pricing :subscription="subscription" />
    </div>

    <ol class="space-y-3">
      <li
        v-for="attribute in subscription.description.attributes"
        class="p2 flex items-center space-x-3 p2"
      >
        <ic-done
          size="18"
          :subscription="subscription.slug"
        />
        <span>{{ attribute }}</span>
      </li>
    </ol>

    <div class="w-full pt-4">
      <the-button
        @click="$emit('select', subscription)"
        :disabled="disabled || selected"
        :inverse="
          (!selected && subscription.slug !== 'pro_plus') || (subscription.slug === 'pro_plus' && !selected && disabled)
        "
        :free="subscription.slug === 'free'"
        :pro="subscription.slug === 'pro'"
        :pro-plus="subscription.slug === 'pro_plus'"
        grow
      >
        <template #default>
          {{ buttonText }}
        </template>
        <template #icon>
          <ic-arrow-up v-if="buttonText === 'Upgrade now'" />
          <ic-arrow-down v-if="buttonText === 'Downgrade'" />
        </template>
      </the-button>
    </div>

    <div
      v-if="subscription.trial_days !== null"
      class="p2 text-bb-cool-grey flex justify-center space-x-2 py-2"
    >
      <ic-clock
        class="mt-1"
        title="Trial time"
        :size="16"
      />
      <div>{{ subscription.trial_days }}-day free trial</div>
    </div>
  </div>
</template>

<script>
import GradientText from '@/components/subscriptions/GradientText'
import IcArrowDown from 'vue-material-design-icons/ArrowDown'
import IcArrowUp from 'vue-material-design-icons/ArrowUp'
import IcDone from '@/components/icon/ic-done'
import IcClock from 'vue-material-design-icons/TimerOutline'
import Pricing from '@/components/subscriptions/Pricing'

export default {
  name: 'subscription-card',
  components: { IcArrowDown, IcArrowUp, GradientText, Pricing, IcDone, IcClock },
  emits: ['select'],
  props: {
    subscription: {
      type: Object,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    showPricing: {
      type: Boolean,
      default: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.subscription-card {
  @apply w-full py-4 px-8 border rounded-xl;
  transition: box-shadow 120ms ease-in;

  &.selected {
    box-shadow: 0 0 10px rgba(110, 25, 240, 0.3);
  }
}
</style>
