<template>
  <span>
    <span class="h3 font-semibold">{{ title }}</span>
    <span
      v-if="!!subscription.pricing.ad_spend"
      class="p1 leading-4 text-bb-mid-grey font-bold"
    >
      +{{ subscription.pricing.ad_spend }}% adspend
    </span>
    <span
      v-if="['pro', 'pro_plus'].includes(subscription.slug)"
      class="p1 leading-4 text-bb-mid-grey font-normal"
    >
      <br />per store / month
    </span>
  </span>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'pricing',
  props: {
    subscription: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      store: 'store/selectedStore',
    }),
    title() {
      if (this.subscription.slug === 'enterprise') return 'Custom'

      if (this.store?.currency_id === 112) {
        return `${this.subscription.pricing.license_fee.SEK} SEK`
      } else {
        return `€${this.subscription.pricing.license_fee.EUR}`
      }
    },
    subtitle() {
      if (this.subscription.slug === 'enterprise') return ''
      if (this.subscription.slug === 'free') return ''

      return this.subscription.pricing.ad_spend
    },
  },
}
</script>
