<template>
  <div class="flex justify-center w-full space-x-4 sm:space-x-8">
    <a
      href="https://bidbrain.com/pricing"
      target="_blank"
      rel="noopener"
      title="Full pricing & features"
      class="flex items-center underline"
    >
      Full pricing & features
      <ic-link
        :size="16"
        class="ml-2"
      />
    </a>

    <a
      href="https://bidbrain.com/pricing#faq"
      target="_blank"
      rel="noopener"
      title="Pricing FAQ"
      class="flex items-center underline"
    >
      Pricing FAQ
      <ic-link
        :size="16"
        class="ml-2"
      />
    </a>
  </div>
</template>

<script>
import IcLink from 'vue-material-design-icons/OpenInNew'

export default {
  name: 'pricing-links',
  components: { IcLink },
}
</script>
